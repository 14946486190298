<h2 mat-dialog-title i18n>Termin vereinbaren</h2>
<mat-dialog-content class="dialog-content">
  <div class="left-section">
    <h3 i18n>Dauer</h3>
    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label i18n>Dauer</mat-label>
        <mat-select>
          <mat-option
            *ngFor="let duration of durationList"
            [value]="duration.value"
          >
            {{ duration.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <h3 i18n>Zeit</h3>
    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label i18n>Gib ein Datum ein</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate i18n-placeholder placeholder="Start-Datum" />
          <input matEndDate i18n-placeholder placeholder="End-Datum" />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <h3 i18n>Agent</h3>
    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label i18n>Agent</mat-label>
        <input
          [formControl]="agentCtrl"
          [matAutocomplete]="auto"
          i18n-aria-label
          aria-label="Agent"
          matInput
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let agent of filteredAgents | async"
            [value]="agent.firstName"
          >
            <img
              [src]="agent.avatarUrl"
              aria-hidden
              class="example-option-img"
              height="25"
            />
            <span>{{ agent.firstName }} {{ agent.lastName }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <h3>Billable</h3>
    <div>
      <mat-checkbox [(ngModel)]="chargeUser" i18n
        >Benutzer belasten</mat-checkbox
      >
      <br />
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Fee / 5 min</mat-label>
        <input
          [disabled]="!chargeUser"
          matInput
          i18n-placeholder
          placeholder="Pro 5 Minuten"
          type="number"
        />
        <mat-icon matSuffix>euro</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <div class="right-section">
    <h3>Availabe Times</h3>
    <div class="time-slots">
      <div *ngFor="let day of availableSlots | async">
        {{ day.dateTime | date : "EEEEEE, dd/MM" }}
        <div
          (click)="selectSlot(slot.id)"
          *ngFor="let slot of day.slots"
          [ngClass]="{ 'slot-selected': slot.id === selectedSlot }"
          class="slot"
        >
          {{ slot.dateTime | date : "HH:mm" }} {{ slot.agent.firstName }}
          {{ slot.agent.lastName }}
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button [mat-dialog-close]="true" cdkFocusInitial mat-button i18n>
    Bestätigen
  </button>
</mat-dialog-actions>
