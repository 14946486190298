import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AppointmentAttachmentsService,
  CallAttachment,
  CurrentAttachmentData,
} from '../../services/appointment-attachments.service';
import { filter, Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Mode } from '../../../annotations/drawing-canvas/drawing-canvas.component';
import { CallCtrlService } from '../../services/call-ctrl.service';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';

@Component({
  selector: 'app-portrait-attachment-list',
  templateUrl: './portrait-attachment-list.component.html',
  styleUrls: ['./portrait-attachment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortraitAttachmentListComponent implements OnInit, OnDestroy {
  public attachments: CallAttachment[] = [];
  private readonly unsubscribe$ = new Subject<void>();
  protected isLoading = true;

  constructor(
    private readonly attachmentService: AppointmentAttachmentsService,
    private readonly cdr: ChangeDetectorRef,
    protected readonly callCtrlService: CallCtrlService
  ) {}

  ngOnInit(): void {
    const attachments$ = this.attachmentService.attachments$.pipe(
      takeUntil(this.unsubscribe$),
      filter((x) => x != null)
    );

    attachments$.subscribe((attachments) => {
      this.attachments = attachments;
      this.isLoading = false;
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected trackImageBy(index: number, item: CallAttachment): string {
    if (!item) return null;
    return item.id;
  }

  protected async deleteAttachment(attachmentId: string) {
    await this.attachmentService.deleteAttachment(attachmentId);
    await this.attachmentService.refreshAttachmentsForAppointment();
  }

  protected async openAttachment(
    path: string,
    event?: Event,
    isInPresentationMode = false
  ) {
    // Maybe use an "allowSelection" Input instead
    this.callCtrlService.modeChannel$.pipe(take(1)).subscribe((mode) => {
      if (mode !== Mode.Annotate_Attachment) {
        const index = this.attachments.findIndex((x) => x.path === path);

        this.attachmentService.changeCurrentAttachment({
          attachments: this.attachments,
          currentIndex: index,
          canBePresented: true,
          isInPresentationMode: isInPresentationMode,
        } as CurrentAttachmentData);
      }
    });
  }

  protected readonly AttachmentContentType = AttachmentContentType;
}
