import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import {
  CallAttachment,
  CurrentAttachmentData,
} from '../../../video-appointment/services/appointment-attachments.service';
import {
  DataTrackObject,
  DataTrackObjectType,
  LocalTrackCtrlService,
} from '../../../video-appointment/services/local-track-ctrl.service';
import { DrawEvent, Mode } from '../../drawing-canvas/drawing-canvas.component';

// https://stackblitz.com/edit/agora-test-pe5pyv?file=src%2Fapp%2Fannotate%2Fannotate.component.ts
@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss'],
})
export class AttachmentDialogComponent implements OnInit {
  public attachments: CallAttachment[] = [];
  public currentIndex = 0;
  public canBePresented = false;

  public modeChannel$: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(
    Mode.Idle
  );

  public Mode = Mode;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CurrentAttachmentData,
    private dialogRef: MatDialogRef<AttachmentDialogComponent>,
    private readonly trackCtrl: LocalTrackCtrlService
  ) {}

  ngOnInit(): void {
    this.attachments = this.data.attachments;
    this.currentIndex = this.data.currentIndex;
    this.canBePresented = this.data.canBePresented;
  }

  close() {
    this.dialogRef.close();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.back();
    }

    if (event.key === 'ArrowRight') {
      this.forward();
    }
  }

  back() {
    this.currentIndex--;
    if (this.currentIndex < 0) this.currentIndex = this.attachments.length - 1;
  }

  forward() {
    this.currentIndex++;
    if (this.currentIndex === this.attachments.length) this.currentIndex = 0;
  }

  public draw(data: DrawEvent) {
    this.trackCtrl.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.ANNOTATE).withData(data)
    );
  }

  public changeMode(event: Mode) {
    if (event === this.modeChannel$.value) {
      this.modeChannel$.next(Mode.Idle);
    }
    this.modeChannel$.next(event);
  }
}
