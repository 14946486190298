<div class="container">
  <ng-container *ngIf="currentAttachment$ | async as currentAttachment">
    <div class="header">
      <div class="header-name">
        <span>{{ currentAttachment.name }}</span>
      </div>
      <span class="header-spacer"></span>
      <div class="header-actions">
        <ng-container
          *ngTemplateOutlet="
            actionsToolbarMenu;
            context: { currentAttachment: currentAttachment }
          "
        ></ng-container>
      </div>
      <span class="header-spacer"></span>
      <div class="header-close">
        <button
          class="header-close-action-menu"
          mat-icon-button
          [matMenuTriggerFor]="actionMenu"
          [matMenuTriggerData]="{ currentAttachment: currentAttachment }"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div
      class="content-container"
      (swipeleft)="onSwipeAttachmentLeft()"
      (swiperight)="onSwipeAttachmentRight()"
    >
      <span class="spacer-left"></span>
      <button
        class="navigate-button"
        mat-icon-button
        color="primary"
        i18n-aria-label
        aria-label="Vorheriges Bild"
        [disabled]="
          currentAttachment.attachmentIdentifier ===
          allAttachments[0].attachmentIdentifier
        "
        (click)="onPreviousAttachmentClicked()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class="spacer"></span>
      @if (currentAttachment.type === AttachmentContentType.Image) {
      <app-view-attachment-image
        class="view-attachment-image"
        [attachment]="currentAttachment"
      >
      </app-view-attachment-image>
      }
      <object
        *ngIf="currentAttachment.type === AttachmentContentType.Pdf"
        class="attachment-pdf"
        [attr.data]="currentAttachment.blobUrl | safe : 'resourceUrl'"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <iframe
          [attr.src]="currentAttachment.blobUrl | safe : 'resourceUrl'"
          width="100%"
          height="100%"
          style="border: none"
        >
          <p>
            Dein Browser unterstützt keine PDFs.
            <a [attr.href]="currentAttachment.blobUrl | safe : 'resourceUrl'"
              >PDF herunterladen</a
            >
          </p>
        </iframe>
      </object>
      <div
        *ngIf="currentAttachment.type === AttachmentContentType.None"
        class="attachment-other"
      >
        <img src="assets/images/description.svg" />
        <button
          mat-flat-button
          color="primary"
          (click)="openAttachment(currentAttachment.blobUrl)"
          i18n
        >
          Herunterladen
        </button>
      </div>
      <span class="spacer"></span>
      <button
        class="navigate-button"
        mat-icon-button
        color="primary"
        i18n-aria-label
        aria-label="Nächstes Bild"
        [disabled]="
          currentAttachment.attachmentIdentifier ===
          allAttachments[allAttachments.length - 1].attachmentIdentifier
        "
        (click)="onNextAttachmentClicked()"
      >
        <mat-icon>arrow_forward</mat-icon>
      </button>
      <span class="spacer-right"></span>
    </div>
    <div class="gallery-container">
      <div class="gallery-content-container">
        <div class="gallery-content-not-visible-container">
          <span
            *ngIf="(notVisibleLeftCount$ | async) > 0"
            class="gallery-content-not-visible-text"
            >+ {{ notVisibleLeftCount$ | async }}</span
          >
        </div>
        <span class="gallery-selected-item-indicator">&#8205;</span>
      </div>

      <div *ngFor="let attachment of attachmentsInPreview | async">
        <div
          class="gallery-content-container"
          *ngIf="
            attachment.attachmentIdentifier ===
              currentAttachment.attachmentIdentifier;
            else unselectedPreview
          "
        >
          <div class="gallery-content-image-container">
            <img
              class="gallery-item gallery-selected-item"
              style="cursor: pointer"
              src="{{
                attachment.annotationThumbnailUrl
                  ? attachment.annotationThumbnailUrl
                  : attachment.thumbnailUrl
                  ? attachment.thumbnailUrl
                  : 'assets/images/description.svg'
              }}"
            />
          </div>
          <span class="gallery-selected-item-indicator">•</span>
        </div>
        <ng-template #unselectedPreview>
          <div
            class="gallery-content-container"
            (click)="onUnselectedAttachmentClicked(attachment)"
          >
            <div class="gallery-content-image-container">
              <img
                class="gallery-item"
                style="cursor: pointer"
                src="{{
                  attachment.annotationThumbnailUrl
                    ? attachment.annotationThumbnailUrl
                    : attachment.thumbnailUrl
                    ? attachment.thumbnailUrl
                    : 'assets/images/description.svg'
                }}"
              />
            </div>
            <span>&#8205;</span>
          </div>
        </ng-template>
      </div>

      <div class="gallery-content-container">
        <div class="gallery-content-not-visible-container">
          <span
            *ngIf="(notVisibleRightCount$ | async) > 0"
            class="gallery-content-not-visible-text"
            >+ {{ notVisibleRightCount$ | async }}</span
          >
        </div>
        <span class="gallery-selected-item-indicator">&#8205;</span>
      </div>
    </div>
  </ng-container>
</div>

<mat-menu #actionMenu>
  <ng-template matMenuContent let-currentAttachment="currentAttachment">
    <button
      mat-menu-item
      (click)="onRotateAttachmentLeft()"
      [disabled]="currentAttachment.type !== AttachmentContentType.Image"
    >
      <mat-icon>rotate_left</mat-icon>
      <span i18n>Links drehen</span>
    </button>

    <button
      mat-menu-item
      (click)="onRotateAttachmentRight()"
      [disabled]="currentAttachment.type !== AttachmentContentType.Image"
    >
      <mat-icon>rotate_right</mat-icon>
      <span i18n>Rechts drehen</span>
    </button>

    <button
      mat-menu-item
      (click)="onReadTextWithOcr()"
      [disabled]="
        (readTextWithOcrDisabled$ | async) ||
        currentAttachment.type !== AttachmentContentType.Image
      "
    >
      <mat-icon>text_format</mat-icon>
      <span i18n>Text auslesen</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #actionsToolbarMenu let-currentAttachment="currentAttachment">
  <div class="action-toolbar-menu">
    <button
      mat-icon-button
      (click)="activateZoom()"
      [disabled]="currentAttachment.type !== AttachmentContentType.Image"
    >
      <mat-icon>zoom_in</mat-icon>
    </button>

    <button
      mat-icon-button
      (click)="onRotateAttachmentLeft()"
      [disabled]="currentAttachment.type !== AttachmentContentType.Image"
    >
      <mat-icon>rotate_left</mat-icon>
    </button>

    <button
      mat-icon-button
      (click)="onRotateAttachmentRight()"
      [disabled]="currentAttachment.type !== AttachmentContentType.Image"
    >
      <mat-icon>rotate_right</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="onReadTextWithOcr()"
      [disabled]="
        (readTextWithOcrDisabled$ | async) ||
        currentAttachment.type !== AttachmentContentType.Image
      "
    >
      <mat-icon>text_format</mat-icon>
    </button>
  </div>
</ng-template>
