import { Component, OnInit } from '@angular/core';
import { QuotaService } from '../../services/api/quota.service';
import { QuotaDataService } from '../../services/data/quota-data.service';
import { Quota, SubscriptionType } from '../../model/Quota';
import { TrialExpiredDialogComponent } from '../../subscription/trial-expired-dialog/trial-expired-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Notification,
  NotificationBarService,
  NotificationType,
} from '../../services/notification-bar.service';
import { RealmService } from '../../authentication/realm.service';
import {
  PlanMessageType,
  PlanMessagingService,
} from '../../services/signaling/messaging/plan-messaging.service';

@Component({
  selector: 'app-quota-display',
  templateUrl: './quota-display.component.html',
  styleUrls: ['./quota-display.component.scss'],
})
export class QuotaDisplayComponent implements OnInit {
  private dialogRef: MatDialogRef<TrialExpiredDialogComponent> = undefined;
  protected showBuyAction = new BehaviorSubject(false);
  protected currentSubscriptionType = new BehaviorSubject(
    SubscriptionType.Trial
  );
  protected SubscriptionType = SubscriptionType;

  constructor(
    realmService: RealmService,
    private readonly quotaService: QuotaService,
    protected readonly quotaDataService: QuotaDataService,
    private readonly planSignalingService: PlanMessagingService,
    private readonly dialog: MatDialog,
    private readonly notificationBarService: NotificationBarService
  ) {
    this.planSignalingService
      .onMessage(PlanMessageType.availableQuotaChanged)
      .subscribe((quota: Quota) => {
        this.quotaDataService.updateQuota(quota);
      });

    this.quotaDataService.employeeQuota.subscribe((quota) => {
      this.currentSubscriptionType.next(quota.subscriptionType);
      if (quota.subscriptionType === SubscriptionType.Trial) {
        if (quota.callsLeftForEmployee <= 0) {
          // show dialog for upgrading to a premium package
          this.showBuyAction.next(true);
          const notification = new Notification(
            $localize`Testversion abgelaufen - Um blankmile weiterhin nutzen zu können, müssen Sie auf eine bezahlte Version wechseln.`,
            NotificationType.Error,
            true
          );
          this.notificationBarService.setNotification(notification);
          this.notificationBarService.setNotificationBarVisibility(true);
        }
      }
    });
  }

  ngOnInit(): void {
    this.updateQuota();
  }

  private updateQuota(): void {
    this.quotaService.getQuota().subscribe({
      next: (quota) => {
        this.quotaDataService.updateQuota(quota);
      },
    });
  }

  onBuyClicked() {
    this.showBuyDialog();
  }

  private showBuyDialog() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(TrialExpiredDialogComponent, {
        width: '65rem',
        disableClose: true,
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = undefined;
      });
    }
  }
}
