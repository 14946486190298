import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  AppointmentAttachmentsService,
  AttachmentAuthor,
} from '../services/appointment-attachments.service';
import { filter, Subject } from 'rxjs';
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CallCtrlService } from '../services/call-ctrl.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-attachment-preview',
  templateUrl: './new-attachment-preview.component.html',
  styleUrls: ['./new-attachment-preview.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition('* => enterY', [
        animate(
          '750ms ease-in-out',
          keyframes([
            style({
              opacity: 0,
              transform: 'translateY(100%)',
            }),
            style({ opacity: 1, transform: 'translateY(0)' }),
          ])
        ),
      ]),
      transition('enterY => leaveY', [
        animate(
          '600ms ease-in-out',
          keyframes([
            style({ opacity: 1, transform: 'translateY(0)' }),
            style({
              opacity: 0,
              transform: 'translateY(100%)',
            }),
          ])
        ),
      ]),
      transition('* => enterX', [
        animate(
          '750ms ease-in-out',
          keyframes([
            style({
              opacity: 0,
              transform: 'translateX(100%)',
            }),
            style({ opacity: 1, transform: 'translateX(0)' }),
          ])
        ),
      ]),
      transition('enterX => leaveX', [
        animate(
          '600ms ease-in-out',
          keyframes([
            style({ opacity: 1, transform: 'translateX(0)' }),
            style({
              opacity: 0,
              transform: 'translateX(100%)',
            }),
          ])
        ),
      ]),
    ]),
  ],
})
export class NewAttachmentPreviewComponent implements OnDestroy {
  protected imgSrc?: string;

  @Input()
  slideInDirection: SlideInDirection = SlideInDirection.X;

  @Output()
  attachmentClicked = new EventEmitter<void>();

  protected slideInState = '';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly attachmentService: AppointmentAttachmentsService,
    protected readonly callCtrlService: CallCtrlService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.attachmentService.newAttachment$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((x) => x.author === AttachmentAuthor.Customer)
      )
      .subscribe((x) => {
        this.slideInState =
          this.slideInDirection === SlideInDirection.Y ? 'enterY' : 'enterX';
        this.imgSrc = x.thumbnail_path;
        setTimeout(() => {
          this.slideInState =
            this.slideInDirection === SlideInDirection.Y ? 'leaveY' : 'leaveX';
          this.cdr.markForCheck();
          this.cdr.detectChanges();
        }, 2500);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  animationStart($event: any) {
    if (this.slideInState === 'enterX' || this.slideInState === 'enterY') {
      $event.element.style.display = 'block';
    }
  }

  animationDone($event: any) {
    if (this.slideInState != 'enterX' && this.slideInState != 'enterY') {
      $event.element.style.display = 'none';
    }
  }

  clicked(): void {
    this.attachmentClicked.emit();
  }
}

export enum SlideInDirection {
  X,
  Y,
}
