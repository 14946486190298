/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InquiryState } from './inquiryState';
import { InvitationDto } from './invitationDto';
import { Gender } from './gender';


export interface InquiryDto { 
    inquiryIdentifier?: string;
    firstName?: string | null;
    lastName?: string | null;
    gender?: Gender;
    email?: string | null;
    phoneNumber?: string | null;
    street?: string | null;
    houseNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
    reCaptchaToken?: string | null;
    notes?: string | null;
    startInstant?: boolean;
    state?: InquiryState;
    scheduledFor?: string | null;
    scheduledForConfirmed?: string | null;
    scheduledForDeclined?: string | null;
    assignedAgent?: string | null;
    invitation?: InvitationDto;
    autoDeletionScheduledAt?: string | null;
    autoDeletedAt?: string | null;
    manuallyDeletedAt?: string | null;
    manuallyDeletedBy?: string | null;
    tenantId?: string | null;
}

