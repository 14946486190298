<div class="side-nav-container">
  <mat-sidenav-container>
    <mat-sidenav [opened]="showSideNav" class="sidebar-container" mode="side">
      <div class="sidebar-stretch">
        <mat-nav-list>
          <mat-list-item
            [routerLinkActive]="'is-nav-item-active'"
            routerLink="/scheduling"
            queryParamsHandling="preserve"
          >
            <mat-icon matListItemIcon>schedule</mat-icon>
            <span matListItemTitle i18n>Termine</span>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-expansion-panel
            class="side-nav-expansion-panel"
            *ngIf="roleService.isInRole(BackendRole.Admin) | async"
          >
            <mat-expansion-panel-header
              collapsedHeight="56px"
              expandedHeight="56px"
              class="side-nav-expansion-panel-header"
              matRipple
            >
              <mat-list-item
                *ngIf="roleService.isInRole(BackendRole.Admin) | async"
                disableRipple
                class="side-nav-expansion-panel-header-item"
              >
                <mat-icon matListItemIcon>apartment</mat-icon>
                <span matListItemTitle i18n>Firma</span>
              </mat-list-item>
            </mat-expansion-panel-header>
            <mat-list-item
              *ngIf="roleService.isInRole(BackendRole.Admin) | async"
              [routerLinkActive]="'is-nav-item-active'"
              routerLink="/company/general"
              queryParamsHandling="preserve"
            >
              <mat-icon matListItemIcon class="side-nav-sub-item-icon"
                >apartment</mat-icon
              >
              <span matListItemTitle i18n>Allgemein</span>
            </mat-list-item>
            <mat-list-item
              *ngIf="roleService.isInRole(BackendRole.Admin) | async"
              [routerLinkActive]="'is-nav-item-active'"
              routerLink="/company/branding"
              queryParamsHandling="preserve"
            >
              <mat-icon matListItemIcon class="side-nav-sub-item-icon"
                >apartment</mat-icon
              >
              <span matListItemTitle i18n>Branding</span>
            </mat-list-item>
            <mat-list-item
              *ngIf="roleService.isInRole(BackendRole.Admin) | async"
              [routerLinkActive]="'is-nav-item-active'"
              routerLink="/company/subscription"
              queryParamsHandling="preserve"
            >
              <mat-icon matListItemIcon class="side-nav-sub-item-icon"
                >apartment</mat-icon
              >
              <span matListItemTitle i18n>Abonnement</span>
            </mat-list-item>
          </mat-expansion-panel>
        </mat-nav-list>
      </div>
      <div class="bottom-section">
        <div>
          <mat-nav-list>
            <mat-divider></mat-divider>
            <mat-list-item
              *ngIf="roleService.isInRole(BackendRole.Manager) | async"
              [routerLinkActive]="'is-nav-item-active'"
              routerLink="/employees"
              queryParamsHandling="preserve"
            >
              <mat-icon matListItemIcon>people</mat-icon>
              <span matListItemTitle i18n>Mitarbeiter</span>
            </mat-list-item>
            <mat-list-item
              [routerLinkActive]="'is-nav-item-active'"
              routerLink="/profile"
              queryParamsHandling="preserve"
            >
              <mat-icon matListItemIcon>account_circle</mat-icon>
              <span matListItemTitle i18n>Profil</span>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <app-install-pwa></app-install-pwa>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="content">
        <router-outlet
          (activate)="onRouterOutletActivate($event)"
        ></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
