import { Injectable } from '@angular/core';
import { AnalyticsService as api } from '../../../api/gen';
import { VideoChatService } from '../../video-appointment/services/videochat.service';
import { distinct, filter, take, takeUntil } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { LocalTrackCtrlService } from '../../video-appointment/services/local-track-ctrl.service';
import { DeviceService } from '../../video-appointment/services/device.service';
import {
  AidarPermissionStatus,
  PermissionService,
} from '../../video-appointment/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private readonly analyticsRepo: api,
    private readonly deviceService: DeviceService,
    videoService: VideoChatService,
    localTrackService: LocalTrackCtrlService,
    permissionService: PermissionService
  ) {
    videoService.activeRoom$
      .pipe(
        map((x) => x?.data?.inquiryIdentifier),
        filter((x) => !!x),
        distinct()
      )
      .subscribe((value) => {
        this.reportClientInfo(value);
        // we want to report device changes as well
        this.deviceService.devicesUpdated$
          .pipe(takeUntil(videoService.activeRoom$.pipe(filter((x) => !x))))
          .subscribe((x) => {
            this.reportAvailableDevices(value);
          });
      });
    localTrackService.videoTrackUpdated$
      .pipe(
        map((x) => x.mediaStreamTrack?.label),
        distinctUntilChanged(),
        mergeMap((x) =>
          videoService.activeRoom$.pipe(
            filter((x) => !!x?.data?.inquiryIdentifier),
            take(1),
            map((room) => {
              return { label: x, id: room?.data?.inquiryIdentifier };
            })
          )
        )
      )
      .subscribe((x) => {
        this.reportUsedCamera(x.id, x.label);
      });

    permissionService
      .observeChanges()
      .pipe(
        distinctUntilChanged(),
        mergeMap((x) =>
          videoService.activeRoom$.pipe(
            filter((x) => !!x?.data?.inquiryIdentifier),
            take(1),
            map((room) => {
              return {
                id: room?.data?.inquiryIdentifier,
                cam: x.currentCamStatus,
                mic: x.currentMicStatus,
              };
            })
          )
        )
      )
      .subscribe((x) => {
        this.reportPermissionUpdate(x.id, x.cam, x.mic);
      });
  }

  private reportAvailableDevices(inquiryId: string): void {
    this.deviceService.devicesUpdated$.subscribe(async (promise) => {
      const devices = await promise;
      this.analyticsRepo
        .analyticsAvailableDevicesAgentPost({
          inquiryId: inquiryId,
          devices: devices?.map((x) => {
            return { label: x.label, kind: x.kind };
          }),
        })
        .subscribe();
    });
  }

  private reportClientInfo(inquiryId: string): void {
    const ua = navigator.userAgent;
    const language = navigator.language;

    this.analyticsRepo
      .analyticsClientInfoAgentPost({
        inquiryId: inquiryId,
        language: language,
        userAgent: ua,
      })
      .subscribe();
  }

  private reportUsedCamera(inquiryId: string, cameraLabel: string): void {
    this.analyticsRepo
      .analyticsUsedCameraAgentPost({
        inquiryId: inquiryId,
        label: cameraLabel,
      })
      .subscribe();
  }

  private reportPermissionUpdate(
    inquiryId: string,
    currentCamStatus: AidarPermissionStatus,
    currentMicStatus: AidarPermissionStatus
  ): void {
    this.analyticsRepo
      .analyticsPermissionUpdateAgentPost({
        inquiryId: inquiryId,
        cameraPermission: AidarPermissionStatus.castToDto(currentCamStatus),
        micPermission: AidarPermissionStatus.castToDto(currentMicStatus),
      })
      .subscribe();
  }
}
