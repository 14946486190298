import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AttachmentWithState } from '../attachments/data/attachment-with-state';
import {
  EditAttachmentDialogData,
  EditAttachmentNoteDialogComponent,
} from '../../../shared/dialogs/edit-attachment-note-dialog/edit-attachment-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver-es';
import {
  DeleteAttachmentDialogComponent,
  DeleteAttachmentDialogData,
} from '../../../shared/dialogs/delete-attachment-dialog/delete-attachment-dialog.component';
import { Attachment } from '../../../model/attachment/attachment';
import { AttachmentFileService } from '../../../services/file/attachment-file.service';
import { AttachmentService } from '../../../services/api/attachment.service';
import { AssociatedCallPeriod } from '../../../model/attachment/associated-call-period';
import { AttachmentState } from '../attachments/data/attachment-state';

@Component({
  selector: 'app-inquiry-details-attachments',
  templateUrl: './inquiry-details-attachments.component.html',
  styleUrls: [
    './inquiry-details-attachments.component.scss',
    '../inquiry-details-tabs.scss',
  ],
})
export class InquiryDetailsAttachmentsComponent implements OnInit, OnDestroy {
  @Input() refresh: Subject<void>;
  @Input() inquiryId: string;
  localAfterAppointmentAttachments: AttachmentWithState[] = [];
  remoteAfterAppointmentAttachments: AttachmentWithState[] = [];
  attachmentsToShow: AttachmentWithState[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly attachmentService: AttachmentService,
    private readonly attachmentFileService: AttachmentFileService,
    private readonly ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.refreshAttachments();
    this.refresh.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.refreshAttachments();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  pushOrUpdateLocalAttachment(attachmentWithState: AttachmentWithState) {
    const index = this.localAfterAppointmentAttachments.findIndex(
      (a) => a.name === attachmentWithState.name
    );
    if (index < 0) {
      this.localAfterAppointmentAttachments.push(attachmentWithState);
    } else {
      this.localAfterAppointmentAttachments[index] = attachmentWithState;
    }
  }

  updateAttachmentsView() {
    this.mergeLocalAndRemoteAttachments();
    this.ref.markForCheck();
  }

  mergeLocalAndRemoteAttachments() {
    this.attachmentsToShow = [];
    this.attachmentsToShow = this.remoteAfterAppointmentAttachments.concat(
      this.localAfterAppointmentAttachments
    );
  }

  onFileSelected(event: Event) {
    if (event.target) {
      const eventTarget = event.target as HTMLInputElement;

      if (eventTarget.files) {
        const files = this.attachmentFileService.fileListToArray(
          eventTarget.files
        );
        this.attachmentFileService
          .uploadAttachments(AssociatedCallPeriod.After, this.inquiryId, files)
          .subscribe({
            next: (attachmentWithState) => {
              this.pushOrUpdateLocalAttachment(attachmentWithState);
              this.updateAttachmentsView();
            },
          });
      }
    }
  }

  private refreshAttachments() {
    this.attachmentService.getAttachments(this.inquiryId, {}).subscribe({
      next: (attachments) => {
        this.remoteAfterAppointmentAttachments = attachments
          .filter((a) => a.period === AssociatedCallPeriod.After)
          .map(
            (att) =>
              new AttachmentWithState(att.name, att, AttachmentState.NONE)
          );
      },
      complete: () => {
        this.updateAttachmentsView();
      },
    });
  }

  editAttachmentNotes(data: Attachment) {
    const dialogRef = this.dialog.open(EditAttachmentNoteDialogComponent, {
      data: {
        attachmentIdentifier: data.attachmentIdentifier,
        attachmentName: data.fileName,
      } as EditAttachmentDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshAttachments();
      }
    });
  }

  downloadAttachment(data: Attachment) {
    const fileName = `${data.fileName}.${data.extension}`;
    saveAs(data.blobUrl, fileName);
  }

  deleteAttachment(data: Attachment) {
    const dialogRef = this.dialog.open(DeleteAttachmentDialogComponent, {
      data: {
        attachment: data,
      } as DeleteAttachmentDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshAttachments();
      }
    });
  }
}
