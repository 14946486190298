import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { format } from 'date-fns';
import {
  from,
  groupBy,
  map,
  mergeMap,
  Observable,
  startWith,
  toArray,
} from 'rxjs';

@Component({
  selector: 'app-new-event-dialog',
  templateUrl: './new-event-dialog.component.html',
  styleUrls: ['./new-event-dialog.component.scss'],
})
export class NewEventDialogComponent implements OnInit {
  durationList: Duration[] = [
    { value: 0, viewValue: '15 min' },
    { value: 1, viewValue: '30 min' },
    {
      value: 2,
      viewValue: '60 min',
    },
  ];

  agentList: Agent[] = [
    {
      id: '1',
      avatarUrl: 'https://picsum.photos/50',
      firstName: 'Martin',
      lastName: 'Schneglberger',
    },
  ];
  agentCtrl = new FormControl('');
  filteredAgents: Observable<Agent[]> = from([]);

  selectedSlot: string | undefined;

  _slots: Slot[] = [
    {
      id: '1',
      dateTime: new Date(),
      agent: {
        id: '1',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Martin',
        lastName: 'Schneglberger',
      },
    },
    {
      id: '2',
      dateTime: new Date(),
      agent: {
        id: '2',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Domnik',
        lastName: 'Reichinger',
      },
    },
    {
      id: '3',
      dateTime: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      agent: {
        id: '2',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Domnik',
        lastName: 'Reichinger',
      },
    },
    {
      id: '4',
      dateTime: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      agent: {
        id: '2',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Domnik',
        lastName: 'Reichinger',
      },
    },
    {
      id: '5',
      dateTime: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      agent: {
        id: '1',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Martin',
        lastName: 'Schneglberger',
      },
    },
    {
      id: '6',
      dateTime: new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
      agent: {
        id: '2',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Andreas',
        lastName: 'Holzapfel',
      },
    },
    {
      id: '7',
      dateTime: new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
      agent: {
        id: '2',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Andreas',
        lastName: 'Holzapfel',
      },
    },
    {
      id: '8',
      dateTime: new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
      agent: {
        id: '1',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Andreas',
        lastName: 'Holzapfel',
      },
    },
    {
      id: '9',
      dateTime: new Date(new Date().getTime() + 72 * 60 * 60 * 1000),
      agent: {
        id: '2',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Andreas',
        lastName: 'Holzapfel',
      },
    },
    {
      id: '10',
      dateTime: new Date(new Date().getTime() + 72 * 60 * 60 * 1000),
      agent: {
        id: '2',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Andreas',
        lastName: 'Holzapfel',
      },
    },
    {
      id: '11',
      dateTime: new Date(new Date().getTime() + 72 * 60 * 60 * 1000),
      agent: {
        id: '1',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Andreas',
        lastName: 'Holzapfel',
      },
    },
    {
      id: '12',
      dateTime: new Date(new Date().getTime() + 72 * 60 * 60 * 1000),
      agent: {
        id: '1',
        avatarUrl: 'https://picsum.photos/50',
        firstName: 'Andreas',
        lastName: 'Holzapfel',
      },
    },
  ];

  availableSlots: Observable<{ dateTime: Date; slots: Slot[] }[]> = from(
    this._slots
  ).pipe(
    groupBy((slot) => format(slot.dateTime, 'MM/dd/yyyy')),
    mergeMap((group) => group.pipe(toArray())),
    map((x) => {
      return { dateTime: x[0].dateTime, slots: x };
    }),
    toArray()
  );

  chargeUser = false;

  constructor() {}

  ngOnInit(): void {
    this.filteredAgents = this.agentCtrl.valueChanges.pipe(
      startWith(''),
      map((agent) =>
        agent ? this._filterAgents(agent) : this.agentList.slice()
      )
    );
  }

  selectSlot(id: string) {
    this.selectedSlot = id;
  }

  private _filterAgents(value: string): Agent[] {
    const filterValue = value.trim().toLocaleLowerCase();
    return this.agentList.filter(
      (agent) =>
        agent.lastName.toLocaleLowerCase().includes(filterValue) ||
        agent.firstName.toLocaleLowerCase().includes(filterValue)
    );
  }
}

interface Duration {
  value: number;
  viewValue: string;
}

interface Agent {
  id: string;
  avatarUrl: string;
  firstName: string;
  lastName: string;
}

interface Slot {
  id: string;
  dateTime: Date;
  agent: Agent;
}
