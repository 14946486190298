import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PluginService } from '../../services/api/plugin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [PluginService],
})
export class LoginComponent implements OnInit {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly pluginService: PluginService
  ) {}

  async ngOnInit() {
    const externalTenantId =
      this.route.snapshot.queryParams['externalTenantId'];
    const externalUserId = this.route.snapshot.queryParams['externalUserId'];

    if (externalTenantId && externalUserId) {
      this.pluginService
        .getCredentials(externalTenantId, externalUserId)
        .subscribe((credentials) => {
          this.authService
            .loginInsecure(credentials.username, credentials.password)
            .then((u) => {
              this.router.navigate(['/scheduling'], {
                queryParams: { hideMenubar: 'true', hideSideNav: 'true' },
              });
            });
        });
    } else {
      await this.authService.login();
    }
  }
}
