import { Component, Inject, LOCALE_ID, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportService } from '../../../../services/api/report.service';
import { BehaviorSubject } from 'rxjs';
import { AttachmentService } from '../../../../services/api/attachment.service';
import { Attachment } from '../../../../model/attachment/attachment';
import { CreateInquirySummaryReportRequest } from '../../../../model/report/request/create-inquiry-summary-report-request';
import { InquirySummaryReportConfiguration } from '../../../../model/report/inquiry-summary-report-configuration';
import { StaticTemplateService } from '../../../../services/api/static-template.service';
import { ReportType } from '../../../../model/report/report-type';
import { ReportOutputFormat } from '../../../../model/report/report-output-format';
import { StaticTemplateIdentifier } from '../../../../model/report/static-template-identifier';
import { StaticTemplate } from '../../../../model/report/static-template';

@Component({
  selector: 'app-create-custom-report-dialog',
  templateUrl: './create-custom-report-dialog.component.html',
  styleUrls: ['./create-custom-report-dialog.component.scss'],
  providers: [ReportService, StaticTemplateService],
})
export class CreateCustomReportDialogComponent {
  private readonly inquiryId: string;
  protected isLoading$: BehaviorSubject<boolean>;

  protected attachmentCheckBox: ReportAttachmentCheckBox = {
    selected: true,
    attachments: [],
  };

  protected allSelected = false;
  protected headingChecked: boolean;
  protected companySettingsChecked: boolean;
  protected inquiryDetailsChecked: boolean;
  protected notesChecked: boolean;
  protected templates = signal<StaticTemplateCultureData[]>([]);
  protected selectedStaticTemplate: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { inquiryId: string },
    private readonly dialogRef: MatDialogRef<CreateCustomReportDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly attachmentService: AttachmentService,
    private readonly staticTemplateService: StaticTemplateService,
    private readonly reportService: ReportService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
    this.inquiryId = data.inquiryId;
    this.isLoading$ = new BehaviorSubject<boolean>(true);
    this.attachmentService
      .getAttachments(this.inquiryId)
      .subscribe((attachments) => {
        this.attachmentCheckBox.attachments = attachments.map((a) =>
          ReportAttachment.fromAttachment(a)
        );
        this.isLoading$.next(false);
      });
    this.headingChecked = true;
    this.companySettingsChecked = true;
    this.inquiryDetailsChecked = true;
    this.notesChecked = true;
    this.setAll(true);

    this.staticTemplateService
      .getStaticTemplatesByIdentifier(
        StaticTemplateIdentifier.InquirySummaryIdentifier
      )
      .subscribe((templates) => {
        this.templates.set(
          templates.map((st) =>
            StaticTemplateCultureData.fromStaticTemplate(st)
          )
        );
        if (this.templates().length > 0) {
          this.selectedStaticTemplate = this.templates()[0].id;
        }
      });
  }

  updateAllSelected() {
    this.allSelected =
      this.attachmentCheckBox.attachments != null &&
      this.attachmentCheckBox.attachments.every((t) => t.selected);
  }

  someSelected(): boolean {
    if (this.attachmentCheckBox.attachments == null) {
      return false;
    }
    return (
      this.attachmentCheckBox.attachments.filter((t) => t.selected).length >
        0 && !this.allSelected
    );
  }

  setAll(selected: boolean) {
    this.allSelected = selected;
    if (this.attachmentCheckBox.attachments == null) {
      return;
    }
    this.attachmentCheckBox.attachments.forEach((t) => (t.selected = selected));
  }

  attachmentTrackBy(index: number, attachment: ReportAttachment) {
    return attachment.id;
  }

  createCustomReport() {
    this.isLoading$.next(true);
    const template = this.templates().find(
      (t) => t.id === this.selectedStaticTemplate
    );
    this.createReport(template.templateIdentifier, template.cultureCode);
  }

  private createReport(templateIdentifier: string, cultureCode: string) {
    const attachmentIdentifiers = this.attachmentCheckBox.attachments
      .filter((a) => a.selected)
      .map((a) => a.id);
    const request = {
      inquiryIdentifier: this.inquiryId,
      configuration: {
        includeHeader: this.headingChecked,
        includeCompanySettings: this.companySettingsChecked,
        includeInquiryInformation: this.inquiryDetailsChecked,
        includeNotes: this.notesChecked,
        includeTimeline: false,
        includeAttachments: this.allSelected || this.someSelected(),
        attachmentIdentifiersToInclude: [...attachmentIdentifiers],
        templateIdentifier: templateIdentifier,
        type: ReportType.InquirySummary,
        outputFormat: ReportOutputFormat.Pdf,
      } as InquirySummaryReportConfiguration,
      cultureCode: cultureCode,
    } as CreateInquirySummaryReportRequest;

    this.reportService.createInquirySummaryReport(request).subscribe({
      next: (result) => {
        this.snackBar.open(
          $localize`Report ${result.fileName} wurde erfolgreich erstellt.`,
          'Ok',
          { duration: 3000 }
        );
        this.dialogRef.close(true);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }
}

export interface ReportAttachmentCheckBox {
  selected: boolean;
  attachments?: ReportAttachment[];
}

export class ReportAttachment {
  id: string;
  name: string;
  thumbnail: string;
  selected: boolean;

  static fromAttachment(attachment: Attachment) {
    return {
      id: attachment.attachmentIdentifier,
      name: attachment.fileName,
      thumbnail: attachment.annotationThumbnailUrl ?? attachment.thumbnailUrl,
      selected: true,
    } as ReportAttachment;
  }
}

class StaticTemplateCultureData {
  id: string;
  templateIdentifier: string;
  cultureCode: string;
  cultureDisplayName: string;

  static fromStaticTemplate(st: StaticTemplate): StaticTemplateCultureData {
    return {
      id: st.id,
      templateIdentifier: st.templateIdentifier,
      cultureCode: st.cultureCode,
      cultureDisplayName:
        StaticTemplateCultureData.getCultureDisplayNameFromCultureCode(
          st.cultureCode
        ),
    } as StaticTemplateCultureData;
  }

  static getCultureDisplayNameFromCultureCode(code: string): string {
    switch (code) {
      case 'de': {
        return $localize`Deutsch`;
      }
      case 'en': {
        return $localize`Englisch`;
      }
      default: {
        return $localize`Deutsch`;
      }
    }
  }
}
