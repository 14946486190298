import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable } from 'rxjs';
import { Employee } from '../../model/employee/employee';
import { ProfileService, UploadAndUpdateAvatar } from '../../../api/gen';

@Injectable({ providedIn: 'root' })
export class AvatarFileService {
  constructor(private readonly profileService: ProfileService) {}

  public uploadAvatar(file: File): Observable<Employee> {
    return new Observable<Employee>((observer) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          if (e.target && e.target.result) {
            const fileName = file.name;
            const content = e.target.result.toString();

            const avatarUploadData = {
              fileName: fileName,
              data: content,
            } as UploadAndUpdateAvatar;

            firstValueFrom(
              this.profileService
                .profileAvatarPost(avatarUploadData)
                .pipe(map((dto) => Employee.fromDto(dto)))
            ).then((value) => {
              observer.next(value);
              observer.complete();
            });
          }
        };
      }
    });
  }
}
