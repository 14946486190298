import { Component } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { ProfileService } from '../../api/gen';
import { ProfileDataService } from '../services/data/profile-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AvatarFileService } from '../services/file/avatar-file.service';
import { Employee } from '../model/employee/employee';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [AvatarFileService],
})
export class ProfileComponent {
  protected employeeInformation$: BehaviorSubject<Employee> =
    new BehaviorSubject<Employee>(null);
  protected loading$: BehaviorSubject<boolean>;

  constructor(
    private readonly profileDataService: ProfileDataService,
    private readonly profileService: ProfileService,
    private readonly avatarFileService: AvatarFileService,
    private readonly snackBar: MatSnackBar
  ) {
    this.loading$ = new BehaviorSubject(false);
    this.refreshEmployeeInformation();

    this.profileDataService.employeeInformation$.subscribe((employee) => {
      this.employeeInformation$.next(employee);
    });
  }

  newAvatarSelected(event: Event) {
    if (event.target) {
      const eventTarget = event.target as HTMLInputElement;
      if (eventTarget.files) {
        if (eventTarget.files.length !== 1) {
          this.snackBar.open(
            $localize`Bitte wähle nur ein Bild als deinen Avatar aus.`,
            null,
            { duration: 5_000 }
          );
          return;
        }

        const uploadSnackbarRef = this.snackBar.open(
          $localize`Lade neuen Avatar hoch...`
        );

        this.avatarFileService.uploadAvatar(eventTarget.files[0]).subscribe({
          next: (employee) => {
            this.profileDataService.updateEmployeeInformation(employee);
          },
          error: () => {
            uploadSnackbarRef.dismiss();
            this.snackBar.open(
              $localize`Datei konnte nicht hochgeladen werden.`,
              null,
              {
                duration: 5_000,
              }
            );
          },
          complete: () => {
            uploadSnackbarRef.dismiss();
          },
        });
      }
    }
  }

  private refreshEmployeeInformation(): void {
    this.loading$.next(true);
    this.profileService
      .profileGet()
      .pipe(map((dto) => Employee.fromDto(dto)))
      .subscribe((value) => {
        this.profileDataService.updateEmployeeInformation(value);
        this.loading$.next(false);
      });
  }
}
