import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InquiryService } from 'projects/helper-client/src/api/gen';
import { sameDay } from '../../util/isSameDay';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.scss'],
})
export class ScheduleDialogComponent implements OnInit {
  readonly timeSlotIntervalMinutes = 15;
  readonly timeSlotIntervalStartHour = 7;
  readonly timeSlotIntervalEndHour = 19;

  protected durationList = [
    { value: 0, viewValue: '15 min' },
    { value: 1, viewValue: '30 min' },
    { value: 2, viewValue: '60 min' },
  ];

  protected selectedSlot: Date | undefined;
  protected selectedDate: Date = new Date();

  protected availableSlots: Date[] = [];

  protected minDate = new Date();

  protected loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ScheduleDialogComponentDialogData,
    private dialogRef: MatDialogRef<ScheduleDialogComponent>,
    private readonly inquiryService: InquiryService
  ) {
    if (this.selectedDate.getHours() >= this.timeSlotIntervalEndHour) {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1);
      this.selectedDate.setHours(this.timeSlotIntervalStartHour, 0, 0, 0);
    }
    this.minDate = this.selectedDate;
  }

  ngOnInit(): void {
    this.createTimeSlots();
  }

  selectSlot(slot: Date) {
    this.selectedSlot = slot;
  }

  protected dateChanged(event: MatDatepickerInputEvent<Date>) {
    this.selectedSlot = null;
    if (sameDay(new Date(), this.selectedDate)) {
      this.selectedDate = new Date();
    }
    this.createTimeSlots();
  }

  protected submit() {
    this.loading = true;
    this.inquiryService
      .contactInquirySchedulePost({
        inquiryIdentifier: this.data.inquiryId,
        newScheduledFor: this.selectedSlot.toISOString(),
      })
      .subscribe((x) => {
        this.dialogRef.close(this.selectedSlot);
      });
  }

  private createTimeSlots() {
    const slotStartHour =
      this.selectedDate.getHours() > this.timeSlotIntervalStartHour
        ? this.selectedDate.getHours()
        : this.timeSlotIntervalStartHour;

    const startDate = new Date(this.selectedDate);
    startDate.setHours(slotStartHour);

    const hours = this.timeSlotIntervalEndHour - slotStartHour;

    const intervalsPerHour = 60 / this.timeSlotIntervalMinutes;

    const remainingSeconds =
      3600 - (startDate.getMinutes() * 60 + startDate.getSeconds());

    const possibleIntervalsInCurrentHour = Math.ceil(
      intervalsPerHour - remainingSeconds / (this.timeSlotIntervalMinutes * 60)
    );

    const startLoop = possibleIntervalsInCurrentHour;

    // To avoid slots like 10:32 if the selected day is today and its e.g. 10:17
    startDate.setHours(slotStartHour, 0, 0, 0);

    this.availableSlots = [];
    for (let i = startLoop; i < hours * intervalsPerHour; i++) {
      const newDate = new Date(
        startDate.getTime() + this.timeSlotIntervalMinutes * i * 60000
      );
      this.availableSlots.push(newDate);
    }
  }
}

export interface ScheduleDialogComponentDialogData {
  inquiryId: string;
}
