import { Injectable } from '@angular/core';
import { SignalingService } from './signaling.service';
import { AppointmentMessagingService } from './messaging/appointment-messaging.service';
import { BehaviorSubject } from 'rxjs';
import { EmployeeMessagingService } from './messaging/employee-messaging.service';
import { InquiryMessagingService } from './messaging/inquiry-messaging.service';
import { NotificationCenterMessagingService } from './messaging/notification-center-messaging.service';
import { PlanMessagingService } from './messaging/plan-messaging.service';

@Injectable({ providedIn: 'root' })
export class SignalingConnectionService {
  private readonly isConnected$ = new BehaviorSubject(false);
  constructor(
    private readonly signalingService: SignalingService,
    private readonly appointmentMessagingService: AppointmentMessagingService,
    private readonly employeeMessagingService: EmployeeMessagingService,
    private readonly inquiryMessagingService: InquiryMessagingService,
    private readonly notificationCenterMessagingService: NotificationCenterMessagingService,
    private readonly planMessagingService: PlanMessagingService
  ) {}

  connect(userId: string) {
    if (!this.isConnected$.value) {
      this.signalingService.joinSignalingGroup(userId).then(() => {
        this.appointmentMessagingService.addAppointmentListener();
        this.employeeMessagingService.addEmployeeMessageListener();
        this.inquiryMessagingService.addInquiryListener();
        this.notificationCenterMessagingService.addNotificationCenterListener();
        this.planMessagingService.addPlanListener();
        this.isConnected$.next(true);
      });
    }
  }

  disconnect() {
    this.signalingService.closeConnection();
  }
}
