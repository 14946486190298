import { Injectable } from '@angular/core';
import {
  SyncAppointmentService,
  VideoAppointmentSyncDto,
} from 'projects/helper-client/src/api/gen';
import { Subject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppointmentAttachmentsService } from './appointment-attachments.service';
import { RecordingService } from './recording.service';
import { ChatService } from '@mad-tech/aidar-live-chat';
import { LiveChatDtoCasts } from '../../chat/message.model';
import {
  AppointmentMessageType,
  AppointmentMessagingService,
} from '../../services/signaling/messaging/appointment-messaging.service';

@Injectable()
export class SyncService {
  // As we can see, this only works well if all services are injected into root
  // However, this might not always be the case, so this requires a rework
  // Pull instead of Push for synced state
  constructor(
    private readonly appointmentMessagingService: AppointmentMessagingService,
    syncRepo: SyncAppointmentService,
    private readonly appointmentRepo: AppointmentAttachmentsService,
    private readonly recordingService: RecordingService,
    private readonly liveChatService: ChatService
  ) {
    appointmentMessagingService
      .onMessage(AppointmentMessageType.Sync)
      .pipe(
        mergeMap((x) => syncRepo.appointmentsRoomInquiryIdSyncGet(x)),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        if (x.isRecording)
          this.recordingService.changeRecordingStatus(x.isRecording);
      });

    appointmentMessagingService
      .onMessage(AppointmentMessageType.Sync_Attachments)
      .subscribe((x) => {
        this.appointmentRepo.updateAttachmentsWithData(x);
      });

    appointmentMessagingService
      .onMessage(AppointmentMessageType.Publish_New_Chat_Message)
      .subscribe((x) => {
        this.liveChatService.newIncomingChatMessage(
          LiveChatDtoCasts.castFromDto(x)
        );
      });

    appointmentMessagingService
      .onMessage(AppointmentMessageType.Is_Recording)
      .pipe(map((x) => !!x))
      .subscribe((x) => this.recordingService.changeRecordingStatus(x));
  }
}
