import { Injectable } from '@angular/core';
import { SignalingService } from '../signaling.service';
import { EmployeeCommands } from '../commands/employee-commands';
import { filter, map, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmployeeMessagingService {
  private messageStream = new Subject<Message>();

  constructor(private readonly signalingService: SignalingService) {}

  public onMessage(type: EmployeeMessageType): Observable<any> {
    return this.messageStream.asObservable().pipe(
      filter((x) => x.type === type),
      map((x) => x.data)
    );
  }

  public addEmployeeMessageListener() {
    this.signalingService
      .getHubConnection()
      .on(EmployeeCommands.EMPLOYEE_CHANGED, (data) => {
        this.messageStream.next(
          new Message(EmployeeMessageType.employeeChanged, data)
        );
      });
    this.signalingService
      .getHubConnection()
      .on(EmployeeCommands.EMPLOYEE_QUOTA_CHANGED, (data) => {
        this.messageStream.next(
          new Message(EmployeeMessageType.employeeQuotaChanged, data)
        );
      });
  }
}

export enum EmployeeMessageType {
  employeeChanged = 0,
  employeeQuotaChanged,
}

class Message {
  constructor(public type: EmployeeMessageType, public data: any) {}
}
