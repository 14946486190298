import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { constants } from '../../../../environments/constants';
import {
  OcrResultDialogComponent,
  OcrResultDialogData,
} from '../../../video-appointment/dialogs/ocr-result-dialog/ocr-result-dialog.component';
import { AttachmentCvService } from '../../../services/api/attachment-cv.service';
import { FoundText } from '../../../model/attachment/found-text';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewAttachmentImageComponent } from '../view-attachment-image/view-attachment-image.component';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';
import { AttachmentWithState } from '../../inquiry-details/attachments/data/attachment-with-state';
import { Attachment } from '../../../model/attachment/attachment';

@Component({
  selector: 'app-view-attachment-dialog',
  templateUrl: './view-attachment-dialog.component.html',
  styleUrls: ['./view-attachment-dialog.component.scss'],
  providers: [AttachmentCvService],
})
export class ViewAttachmentDialogComponent {
  @ViewChild(ViewAttachmentImageComponent)
  imageAttachment: ViewAttachmentImageComponent;

  protected currentAttachment$: BehaviorSubject<Attachment>;
  protected allAttachments: Attachment[];
  protected attachmentsInPreview = new BehaviorSubject<Attachment[]>([]);

  private readonly arrowLeftKey = 'ArrowLeft';
  private readonly arrowRightKey = 'ArrowRight';

  private readonly sliceSize: number;
  protected notVisibleLeftCount$: BehaviorSubject<number>;
  protected notVisibleRightCount$: BehaviorSubject<number>;

  protected readTextWithOcrDisabled$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewAttachmentData,
    private dialogRef: MatDialogRef<ViewAttachmentDialogComponent>,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly attachmentCvService: AttachmentCvService
  ) {
    const defaultSliceSize =
      window.innerWidth < constants.dimension.$md ? 3 : 5;
    this.sliceSize =
      data.allAttachments.length < 5
        ? data.allAttachments.length
        : defaultSliceSize;
    this.allAttachments = data.allAttachments;
    this.currentAttachment$ = new BehaviorSubject<Attachment>(
      data.currentAttachment
    );

    this.notVisibleLeftCount$ = new BehaviorSubject<number>(0);
    this.notVisibleRightCount$ = new BehaviorSubject<number>(
      this.allAttachments.length - this.attachmentsInPreview.value.length
    );
    this.updateAttachmentsInPreview();
  }

  close() {
    this.dialogRef.close();
  }

  onUnselectedAttachmentClicked(attachment: Attachment) {
    this.currentAttachment$.next(attachment);
    this.updateAttachmentsInPreview();
  }

  onPreviousAttachmentClicked() {
    const currentIndex = this.getIndexOfCurrentAttachment();
    if (currentIndex > 0) {
      this.currentAttachment$.next(this.allAttachments[currentIndex - 1]);
      this.updateAttachmentsInPreview();
    }
  }

  onNextAttachmentClicked() {
    const currentIndex = this.getIndexOfCurrentAttachment();
    if (currentIndex < this.allAttachments.length - 1) {
      this.currentAttachment$.next(this.allAttachments[currentIndex + 1]);
      this.updateAttachmentsInPreview();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === this.arrowLeftKey) {
      this.onPreviousAttachmentClicked();
    } else if (event.key === this.arrowRightKey) {
      this.onNextAttachmentClicked();
    }
  }

  private updateAttachmentsInPreview() {
    const currentIndex = this.getIndexOfCurrentAttachment();
    const mid = Math.floor(this.sliceSize / 2);

    let start = currentIndex - mid >= 0 ? currentIndex - mid : 0;
    let end =
      currentIndex + mid + 1 <= this.allAttachments.length
        ? currentIndex + mid + 1
        : this.allAttachments.length;

    // Expand range if we are too far left
    if (start == 0) {
      const diff = this.sliceSize - end;
      end = end + diff;
    }

    // Expand range if we are too far right
    if (end == this.allAttachments.length) {
      const diff = this.sliceSize - (end - start);
      start = start - diff;
    }

    // Emit not visible count
    this.notVisibleLeftCount$.next(start);
    this.notVisibleRightCount$.next(this.allAttachments.length - end);

    this.attachmentsInPreview.next(this.allAttachments.slice(start, end));
  }

  private getIndexOfCurrentAttachment(): number {
    const currentAttachmentId =
      this.currentAttachment$.value.attachmentIdentifier;

    for (let i = 0; i < this.allAttachments.length; i++) {
      if (currentAttachmentId === this.allAttachments[i].attachmentIdentifier) {
        return i;
      }
    }

    return -1;
  }

  onSwipeAttachmentLeft() {
    this.onNextAttachmentClicked();
  }

  onSwipeAttachmentRight() {
    this.onPreviousAttachmentClicked();
  }

  openAttachment(blobUrl: string) {
    window.open(blobUrl, '_blank');
  }

  onReadTextWithOcr() {
    this.readTextWithOcrDisabled$.next(true);
    const currentAttachment = this.currentAttachment$.value;
    if (currentAttachment) {
      this.snackBar.open($localize`Lese Text aus Foto...`);
      this.attachmentCvService
        .readTextFromAttachment(currentAttachment.attachmentIdentifier)
        .subscribe({
          next: (foundTexts) => {
            this.showOcrResults(foundTexts);
          },
          error: () => {
            this.showOcrError(
              $localize`Text konnte nicht von Anhang gelesen werden.`
            );
          },
        });
    } else {
      this.showOcrError($localize`Kein Anhang zum Auslesen gefunden.`);
    }
  }

  private showOcrResults(foundTexts: FoundText[]) {
    this.snackBar.dismiss();
    const dialogRef = this.dialog.open(OcrResultDialogComponent, {
      data: { foundTexts: foundTexts } as OcrResultDialogData,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.readTextWithOcrDisabled$.next(false);
    });
  }

  private showOcrError(errorText: string) {
    this.snackBar.dismiss();
    this.snackBar.open(errorText, null, { duration: 5000 });
    this.readTextWithOcrDisabled$.next(false);
  }

  activateZoom() {}

  onRotateAttachmentLeft() {
    this.imageAttachment.onRotateAttachmentLeft();
  }

  onRotateAttachmentRight() {
    this.imageAttachment.onRotateAttachmentRight();
  }

  protected readonly AttachmentContentType = AttachmentContentType;
}

export class ViewAttachmentData {
  currentAttachment: Attachment;
  allAttachments: Attachment[];
}
