<div class="img-wrap">
  <div>
    <mat-icon
      *ngIf="attachment.state === AttachmentState.UPLOADING"
      class="state uploading"
      >sync</mat-icon
    >
    <mat-icon
      *ngIf="attachment.state === AttachmentState.SUCCESSFUL"
      class="state success"
      >check</mat-icon
    >
    <mat-icon
      *ngIf="attachment.state === AttachmentState.FAILURE"
      class="state failure"
      >error</mat-icon
    >
  </div>
  <img
    [src]="
      attachment?.attachment?.previewSource
        ? attachment?.attachment?.previewSource
        : 'assets/images/description.svg'
    "
    class="preview-image"
  />
</div>
